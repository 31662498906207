import React, { useEffect } from "react"
import { useLocation } from "@reach/router"
import "../assets/bootstrap.min.css"
import "../styles/style.scss"
import Header from "./header"
import Footer from "./footer"
import FooterHybrid from "./footer-hybrid"
const Layout = props => {
  const { page } = props
  const { type } = props
  const location = useLocation()

  useEffect(() => {
    const fullUrl = `${location.pathname}${location.search}${location.hash}`
    // Store the current URL in localStorage whenever it changes

    if (!fullUrl.includes("login")) {
      // Store the full URL in localStorage if it doesn't contain "login"
      localStorage.setItem("lastVisitedUrl", fullUrl)
    }
  }, [location])

  return (
    <div className={props.type ? props.type : ""}>
      <Header />
      {props.children}

      <Footer pageType={page} meetingClass={type} />
    </div>
  )
}
export default Layout
